import {createBrowserRouter} from "react-router-dom";
import Home from "../pages";

export default createBrowserRouter([
    {
        path: '',
        element: <Home />,
    },
    {
        path: '/kamikaze',
        element: <Home />,
    },
])