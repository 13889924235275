export default {
    desc13: '登录',
    desc14: '用户名',
    desc15: '密码',
    desc16: 'Game ID (非必填)',
    desc17: '请输入',
    tryGame: '试玩游戏',

    nav: ['游戏', '彩池', '线下场馆', '联系我们'],
    banner: [
        {
            title: '引领实体老虎机线上投注潮流',
            desc: '通过移动设备远端操控实体老虎机 , 享受全球最大奖池 , 流畅体验在线老虎机操控的乐趣 , 为您的客户提供数百种游戏选择。',
            btn: '免费试玩'
        },
        {
            title: '权威机构认证',
            desc: '我们的老虎机台全部来自于正规厂家 , 所有参数调控及业务受到博彩委员会监管 , 保证玩家游戏公平 , 结果可靠。',
        }
    ],
    introduction: {
        title: '行业的创新者',
        desc: 'islot 是一家创新游戏开发商，提供不同类型的线上实体赌场游戏。我们致力于将世界各地赌场内最受欢迎的老虎机游戏带到线上',
        list: [
            '我们的团队成员来自世界各大赌场，拥有丰富的赌场管理、开发经验',
            '只需要一个API即可完美对接各种终端设备, 不论在哪个地区，我们都可提供对应的语言服务',
            '持有政府监管机构的授权合法执照证书,参与博彩委员会监管, 确保经营合法合规',
            '拥有多个大型场馆, 世界各地赌场老虎机, 多种游戏款式, 保持更新',
        ],
        numList: [
            {num: '500+', title: '专业雇员'},
            {num: '640+', title: '正式运营机台'},
            {num: '10', title: '连线彩池数量'},
            {num: '30+', title: '游戏数量'},
            {num: '20+', title: '线上赌场合作伙伴'},
            {num: '100万美元+', title: '所有彩池总金额'},
            {num: '5000+', unit: '平方米', title: '线下场馆规模'},
            {num: '8', title: '支持语言'},
        ]
    },
    game: {
        title: '体验我们的游戏',
        desc: 'iSLOT提供数十种实体老虎机游戏，FreeGame、JackPot应有尽有，操作体验还原线下',
        btn: '免费试玩'
    },
    jackpot: {
        title: '最大的在线奖池',
        desc: '我们目标是在2024年打造全世界最多的机台和多款游戏的累积大奖池',
    },
    map: {
        title: '为不同地区的玩家提供他们最爱的游戏',
        desc: '我们为不同地区的合作伙伴提供本地化且受欢迎的热门游戏, 卓越的技术支持全球化发展',
        name: ['日本', '欧洲', '南美洲', '北美洲', '大洋洲']
    },
    business: {
        title: '商务合作',
        desc: '我们的目标是为不同国家和地区提供特别个性化服务，不仅局限于老虎机',
        list: [
            {
                title: '包网系统',
                desc: '一站式解决您的建站需求，可自定义服务内容，根据您的需求量身定制',
                arr: ['线上赌场平台', '游戏API接入', '代理系统', '支付系统解决方案']
            },
            {
                title: '老虎机投资',
                desc: '直接参与老虎机的采购投资或租用机台,享受利润分成,同时也欢迎更多种类的合作沟通',
                arr: ['投资购买机台', '租用机台', '全球战略洽谈', '实体老虎机活动']
            },
        ]
    },
    venues: {
        title: '我们的线下场馆',
        desc: '线下3大场馆，承载上千台老虎机，24小时值班监控，保证玩家顺畅游戏',
        tips: '场馆机台展示',
    },
    connect: {
        title: '联系我们，抓住行业机遇',
        desc: '我们提供多种合作方式，保持市场开放性，倾听客户的声音，努力提供最优质的服务。',
        btn: '商务专员Telegram',
    }
}