import {useEffect, useRef, useState, lazy} from "react";
import {changeLanguage} from "i18next";
import {locale} from "@/i18n";
const Header = lazy(() => import('@/pages/Header'))
const Banner = lazy(() => import('@/pages/Banner'))
const Introduction = lazy(() => import('@/pages/Introduction'))
const Game = lazy(() => import('@/pages/Game'))
const Jackpot = lazy(() => import('@/pages/Jackpot'))
const Map = lazy(() => import('@/pages/Map'))
// const Business = lazy(() => import('@/pages/Business'))
const Venues = lazy(() => import('@/pages/Venues'))
const Footer = lazy(() => import('@/pages/Footer'))
const Login = lazy(() => import('@/pages/Login'))

function Home() {
    const [lang, setLang] = useState(locale)
    const [isH5, setIsH5] = useState(null)
    const event = useRef(null)

    useEffect(() => {
        const onResize = () => {
            const IsH5 = window.innerWidth < 1200
            if (IsH5 !== isH5) {
                setIsH5(IsH5)
                localStorage.setItem('isH5', IsH5)
                document.getElementsByTagName('html')[0].setAttribute('class', IsH5 ? 'H5' : 'PC')
            }
        }
        onResize()
        window.addEventListener('resize', onResize)
        return () => window.removeEventListener('resize', onResize)
    }, [isH5])

    const changeLang = lang => {
        setLang(lang)
        changeLanguage(lang)
    }

    return (
        isH5 !== null && (
            <div key={String(lang)}>
                <Header changeLang={changeLang} lang={lang} isH5={isH5} />
                <Banner isH5={isH5} event={event} />
                <Introduction isH5={isH5} />
                <Game isH5={isH5} lang={lang} event={event} />
                <Jackpot isH5={isH5} lang={lang} />
                <Map isH5={isH5} />
                {/*<Business isH5={isH5} />*/}
                <Venues isH5={isH5} />
                <Footer isH5={isH5} />
                <Login isH5={isH5} ref={event} />
            </div>
        )
    );
}

export default Home;