import i18n from 'i18next'
import {initReactI18next} from 'react-i18next'
import en from './en'
import zh from './zh'

export const resources = {
    en: {
        translation: en
    },
    zh: {
        translation: zh
    }
}
const navigatorLang = navigator.language.slice(0, 2)
export const locale = localStorage.getItem('lang') || (Object.keys(resources).includes(navigatorLang) ? navigatorLang : 'en')

i18n.use(initReactI18next).init({
    resources,
    lng: locale,
    returnObjects: true,
    interpolation: {
        escapeValue: false,
    }
})
