export default {
    desc13: 'Log in',
    desc14: 'User name',
    desc15: 'Password',
    desc16: 'Game ID (Optional)',
    desc17: 'Please enter',
    tryGame: 'Trial Games',

    nav: ['Games', 'Jackpot', 'Offline Venues', 'Contact Us'],
    banner: [
        {
            title: 'Leads the trend of slot machine',
            desc: 'Remote access to casino slot through mobile devices, restoring the most authentic betting experience in the live broadcast',
            btn: 'FREE PLAY'
        },
        {
            title: 'Certified by authoritative institutions',
            desc: 'Our machines are all from original factories, certified by authoritative gaming organizations and regulated by gaming associations to ensure that every game process is fair, just and open, guaranteeing the legitimate interests of every user',
        }
    ],
    introduction: {
        title: 'Innovators of igaming',
        desc: 'iSLOT is a innovative game developer，providing different kinds of land-base casino games online. Our vision is to bring the most popular games from casinos around the world online so that players can experience the pure casino experience without physical limitations.',
        list: [
            'iSLOT has more than 500 R&D personnel, and on-site managers are from major casinos in the world.',
            'The largest global procurement, to support the preferences of players around the world.',
            'Various agency and license certifications, can operate legally in 20+ countries and region.',
            'Super-sized offline venues over 5000 square meters each, a lot of machine &Keep the game updated.',
        ],
        numList: [
            {num: '500+', title: 'Employee working hard'},
            {num: '640+', title: 'Slot machine online'},
            {num: '10', title: 'Numbers of JackPot link'},
            {num: '30+', title: 'Number of games'},
            {num: '20+', title: 'Online casino partner'},
            {num: '$ 1 Million+', title: 'Sum of JackPot numbers'},
            {num: '5000+', unit: 'sqm', title: 'Offline venues size'},
            {num: '8', title: 'Supported languages'},
        ]
    },
    game: {
        title: 'Try our exciting games',
        desc: 'iSLOT stands out as a pioneering solution, specializing in unique online adaptations of land-based casino games. Our distinctive approach allows players to enjoy the authentic casino atmosphere virtually, eliminating physical constraints and offering an unparalleled gaming experience.',
        btn: 'FREE PLAY'
    },
    jackpot: {
        title: 'Biggest Jackpot Link',
        desc: 'We are moving towards our goal and expect to build the world\'s largest JackPot link in 2024, with a total number of units reaching 30,000+',
    },
    map: {
        title: 'Provide games that players from all regions like',
        desc: 'Our goal is to provide special services for different countries and regions, not limited to slot machines, but also other types such as pachinko.',
        name: ['JAPAN', 'EUROPE', 'SOUTH AMERICA', 'NORTH AMERICA', 'OCEANIA']
    },
    business: {
        title: 'Business cooperation',
        desc: 'Our goal is to provide special services for different countries and regions, not limited to slot Machines',
        list: [
            {
                title: 'Turnkey Solution',
                desc: 'A one-stop solution for your website development needs, offering customizable services tailored to your requirements.',
                arr: ['Online Casino Platform', 'Casino Games API', 'Affiliate System', 'Payment solution']
            },
            {
                title: 'Slot Machine Investment',
                desc: 'Engage directly in the procurement investment of slot machines or lease the machines, enjoy profit sharing. We also welcome discussions for various types of collaborations.',
                arr: ['Buy or Rent Machines', 'Machines Agent', 'Global strategic cooperation', 'Activity']
            },
        ]
    },
    venues: {
        title: 'Our offline venues',
        desc: 'There are 3 large venues under the line, hosting thousands of slot machines, with 24-hour monitoring to ensure that players can continue to play.',
        tips: 'Our on-site lobby',
    },
    connect: {
        title: 'Contact us to seize industry opportunities',
        desc: 'We provide a myriad of collaboration avenues, uphold market inclusivity, attentively heed customer voices, and relentlessly deliver premium services.',
        btn: 'Official Telegram',
    }
}